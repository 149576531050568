import axios from "axios";

export default axios.create({
  // THIS IS THE ONLY PORT CHANGE NEEDED FOR DEPLOYMENT
  baseURL: "https://finally.irco.gr:443/api",
  //baseURL: "http://localhost:443/api",
  headers: {
    "Content-type": "application/json",
  },
});

import React, { Component } from "react";
import { withRouter } from "../../../common/with-router";
import PaymentDataService from "../../../services/payment.service";
import PaymentItemDataService from "../../../services/paymentitem.service";

import Modal from "../stripe/Modal";

class PaymentButton extends Component {
  constructor(props) {
    super(props);
    //console.log(this.props);
    //console.log(this.props.router.params.id);

    this.showModal = this.showModal.bind(this);
    this.showTheFuckinModal = this.showTheFuckinModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleClickPayFull = this.handleClickPayFull.bind(this);
    this.trackChosenItems = this.trackChosenItems.bind(this);

    this.state = {
      show: false,
    };
  }

  trackChosenItems(data) {
    var payitemsnumber = this.props.payitemsnumber;
    var descArr;

    for (let i = 0; i < payitemsnumber; i += 3) {
      /*       this.props.arr.push("uogciykcflugvhbicryfk");
       */
      /* descArr.push(this.props.arr[i + 1]);
      console.log("in", descArr); */

      data["orderItemId"] = this.props.arr[i];
      data["description"] = this.props.arr[i + 1];
      data["amount"] = this.props.arr[i + 2];
      PaymentItemDataService.create(data)
        .then((response) => {})
        .catch((e) => {
          console.log(e);
        });
    }
    /*     this.setState(descArr);
    console.log("out", descArr); */
  }

  showModal = () => {
    var data = {
      order_id: this.props.oID,
      client_id: 4,
      amount: this.props.MasterTotal,
      split_method: this.props.SplitMethod,
    };

    var payment_id = 0;

    PaymentDataService.create(data)
      .then((response) => {
        this.setState({});
        payment_id = response.data.id;
        this.setState({ payment_id });
        if (this.props.SplitMethod == "ChooseYourItems") {
          var data2 = {
            payment_id: payment_id,
            order_id: this.props.oID,
            amount: 0,
            description: "",
            orderItemId: -1,
          };
          this.trackChosenItems(data2);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  showTheFuckinModal = () => {
    this.setState(this.showModal);
  };

  handleClickPayFull() {
    var toPay = this.props.MasterTotal;
    var billTotal = this.props.billTotal;
    var remainingAmount = this.props.remainingAmount;

    if (toPay < 0.5) {
      console.log(
        toPay,
        "Your amount you are paying is less than 0.50 and cannot be processed"
      );
    } else if (remainingAmount < toPay) {
      console.log(
        toPay,
        "remaining cannot be bigger than paying, will be set to remaining"
      );
      toPay = remainingAmount;
      console.log(toPay, "paying amount");
      this.setState({ toPay }, this.showModal);
    } else if (remainingAmount - toPay < 0.5 && remainingAmount - toPay > 0) {
      console.log(
        toPay,
        "remaining cannot be less than 0.50, it will be added to your paying amount"
      );
      toPay = remainingAmount;
      console.log(toPay, "paying amount");
      this.setState({ toPay }, this.showModal);
    } else {
      console.log(toPay, " amount");
      this.setState(
        {
          /*  {
        MasterTotal:
          this.state.currentTutorial2.total + this.state.tip_percentage,
        },*/
          toPay,
        },
        this.showModal
      );
    }
  }

  render() {
    const { currentTutorial2, tip_percentage, toPay } = this.state;

    return (
      <div>
        {this.state.show ? (
          <Modal
            show={this.state.show}
            handleClose={this.hideModal}
            toPay={toPay}
            id={this.props.oID}
            payment_id={this.state.payment_id}
            split_method={this.props.SplitMethod}
          ></Modal>
        ) : (
          <nothing />
        )}

        <button className="m-3 btnpay btn-sm" onClick={this.handleClickPayFull}>
          PAY €{this.props.MasterTotal.toFixed(2)}
        </button>
      </div>
    );
  }
}

export default withRouter(PaymentButton);

import React, { useState, useEffect } from "react";
import PaymentDataService from "../../services/payment.service";
import OrderItemDataService from "../../services/orderitem.service";
import Counter from "./split/Counter.component";
import CustomAmount from "./split/CustomAmount.component";
import AddTip from "./AddTip.component";
import ChooseThings2 from "./split/ChooseThings2.component";
import PaymentButton from "./items/PaymentButton.component";

const ItemsList2 = ({ oID }) => {
  const [showPayFullBtn, setShowPayFullBtn] = useState(true);
  const [currentOrder, setCurrentOrder] = useState([]);
  const [SumAmount, setSumAmount] = useState([{ data: null }]);
  const [SumPrice, setSumPrice] = useState([{ data: 0 }]);
  const [SumAm, setSumAm] = useState(0);
  const [SumPr, setSumPr] = useState(0);
  const [MasterTotal, setMasterTotal] = useState(0);
  const [event1, setEvent1] = useState(0);
  const [theTotal, setTheTotal] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [clicked2, setClicked2] = useState("none");
  const [remainingAmount, setRemainingAmount] = useState(0);

  useEffect(() => {
    OrderItemDataService.sumPrice(oID)
      .then((response) => {
        var a = response.data;
        var b = a.data;
        setSumPr(b);
        setTheTotal(b);

        PaymentDataService.sumPaid(oID)
          .then((response) => {
            // console.log(response.data);
            var c = response.data;
            var d = c.data;
            // console.log(d);
            // console.log(c);
            setSumAm(d);
            // console.log(SumAm);
            setRemainingAmount(b - d);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }, [oID]);

  const handleClick = () => {
    setClicked(true);
    setShowPayFullBtn(false);
  };

  const handleClick2 = (clicked2a) => {
    setClicked2(clicked2a);
  };

  const back = () => {
    setClicked(false);
    setShowPayFullBtn(true);
    setClicked2("none");
  };

  const back2 = () => {
    setClicked2("none");
  };

  const handleTotal = (langValue) => {
    setTheTotal(langValue);
  };

  return (
    <div className="list row, arxikiSection3">
      {/*       {<div className="total"> Total: {SumPr} €</div>}
       */}{" "}
      <div className="total"> Total: {parseFloat(SumPr).toFixed(2)} €</div>
      {SumAm < SumPr && SumPr ? (
        <div className="col-md-5 yeah">
          <div>
            {SumAm === 0 || SumAm === null ? (
              <div className="nooneHasPaid">noone has paid yet</div>
            ) : (
              <div>
                <h5></h5>
                <div className="total">
                  Remaining:{" "}
                  {SumPr -
                    //+ Number(tip_percentage)
                    SumAm}{" "}
                  €
                </div>
              </div>
            )}
          </div>

          {/* ########## BUTTONS START HERE ############ */}
          {<AddTip MasterTotal={handleTotal} y={SumPr}></AddTip>}
          {/*     <div className="total2 clearhere">
          Total with Tip: {this.state.theTotal.toFixed(2)}
        </div>
       */}
          {showPayFullBtn ? (
            <PaymentButton
              // MasterTotal={this.state.currentTutorial2.total}
              MasterTotal={theTotal}
              SplitMethod="PayInFull"
              billTotal={theTotal}
              remainingAmount={remainingAmount}
              oID={oID}
            ></PaymentButton>
          ) : (
            <div></div>
          )}

          {clicked ? (
            <div>
              {/* ########## DIVIDE ############ */}
              {clicked2 === "divide" ? (
                <div>
                  <div>
                    <button
                      className="m-3 btnbk btn-sm clearhere"
                      onClick={back2}
                    >
                      back
                    </button>{" "}
                    <div id="mount-point"></div>
                    <Counter
                      MasterTotal={theTotal}
                      billTotal={theTotal}
                      remainingAmount={remainingAmount}
                    />
                  </div>
                </div>
              ) : clicked2 === "choose" ? (
                <div>
                  <div>
                    <button
                      className="m-3 btnbk btn-sm clearhere"
                      onClick={back2}
                    >
                      back
                    </button>{" "}
                    <ChooseThings2
                      oID={oID}
                      MasterTotal={theTotal}
                      billTotal={theTotal}
                      remainingAmount={remainingAmount}
                    />
                  </div>
                </div>
              ) : clicked2 === "custom" ? (
                <div>
                  <button
                    className="m-3 btnbk btn-sm clearhere"
                    onClick={back2}
                  >
                    back
                  </button>{" "}
                  <CustomAmount
                    MasterTotal={theTotal}
                    billTotal={theTotal}
                    remainingAmount={remainingAmount}
                  />
                </div>
              ) : (
                <div>
                  <button className="m-3 btnbk btn-sm clearhere" onClick={back}>
                    back
                  </button>

                  {/*         <div className="total">
                  Total: {currentTutorial2.total}€ + tip: {tip_percentage}€ ={" "}
                  {currentTutorial2.total + Number(tip_percentage)} €
                </div> */}

                  <button
                    className="m-3 btnpay2 btn-sm "
                    onClick={() => handleClick2("divide")}
                  >
                    divide
                  </button>

                  <button
                    className="m-3 btnpay2 btn-sm "
                    onClick={() => handleClick2("choose")}
                  >
                    choose my own things
                  </button>

                  <button
                    className="m-3 btnpay2 btn-sm "
                    onClick={() => handleClick2("custom")}
                  >
                    custom amount
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div>
              <button className="m-3 btnpay2 btn-sm " onClick={handleClick}>
                SPLIT
              </button>
            </div>
          )}
        </div>
      ) : (
        <p>This order has been paid! Thanks</p>
      )}
    </div>
  );
};

export default ItemsList2;

import React, { Component } from "react";
import { withRouter } from "../../../common/with-router";
import PaymentButton from "../items/PaymentButton.component";

class CustomAmount extends Component {
  constructor(props) {
    super(props);
    //console.log(this.props);

    /*     this.handleSubmit = this.handleSubmit.bind(this);
     */ this.handleCustomAmount = this.handleCustomAmount.bind(this);

    this.state = {
      MasterTotal: 0,
      show: false,
    };
  }

  handleCustomAmount(value) {
    //this.state.total = this.props.y + value;
    //this.props.MasterTotal(this.state.total);
    this.setState({
      MasterTotal: value,
    });
  }

  /*   handleSubmit = (event) => {
    event.preventDefault();
    //console.log(event.target.CustomAmount.value);
    this.setState({
      MasterTotal: event.target.CustomAmount.value,
    });
  }; */

  render() {
    return (
      <div>
        choose your amount:
        <form
          className="form1"
          noValidate
          autoComplete="off"
          /*           onSubmit={this.handleSubmit}
           */
        >
          <input
            type="number"
            placeholder="custom"
            inputMode="numeric"
            /* pattern="[0-9]*" */
            name="CustomAmount"
            max={this.props.MasterTotal}
            step="0.01"
            onChange={(e) =>
              e.target.value
                ? this.handleCustomAmount(parseFloat(e.target.value))
                : this.handleCustomAmount(parseFloat(0))
            }
          />
          {/*  {console.log(this.props.MasterTotal, "this")} */}
          {/* <button className="m-3 btn-sm tip" type="submit">
            OK
          </button> */}
        </form>
        <PaymentButton
          MasterTotal={this.state.MasterTotal}
          SplitMethod="CustomAmount"
          billTotal={this.props.billTotal}
          remainingAmount={this.props.remainingAmount}
        ></PaymentButton>
      </div>
    );
  }
}
export default withRouter(CustomAmount);

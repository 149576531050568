import React, { useState, useEffect } from "react";

const Menu = ({ handleClose, show, children, oID, menuPath }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <div className="menupdf">
        <embed src={menuPath} type="application/pdf" />
      </div>
      <button className="menubtn" type="button" onClick={handleClose}>
        BACK
      </button>
    </div>
  );
};
export default Menu;

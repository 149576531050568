import React, { Component } from "react";
import { withRouter } from "../../../common/with-router";
import PaymentButton from "../items/PaymentButton.component";

class Counter extends Component {
  constructor(props) {
    super(props);
    //console.log(this.props);

    this.incrementCount = this.incrementCount.bind(this);
    this.decrementCount = this.decrementCount.bind(this);
    this.incrementCount2 = this.incrementCount2.bind(this);
    this.decrementCount2 = this.decrementCount2.bind(this);

    this.state = {
      count: 2,
      count2: 1,
    };
  }

  incrementCount() {
    this.setState({
      count: this.state.count + 1,
    });
  }
  decrementCount() {
    this.setState({
      count: this.state.count - 1,
    });
  }
  incrementCount2() {
    this.setState({
      count2: this.state.count2 + 1,
    });
  }
  decrementCount2() {
    this.setState({
      count2: this.state.count2 - 1,
    });
  }

  render() {
    //      const { currentTutorial2, tip, tip_percentage, amount } = this.state;
    const baseClass = "counter";
    const buttonBgState = this.state.count <= 0 ? "bg-red" : "bg-blue";

    return (
      <div>
        {/*}   <br></br>
         <h5>remaining:</h5>
          <div className="total"> {currentTutorial2.total + Number(tip_percentage) - currentTutorial2.paid_amount}€</div>
          <br></br>
      */}
        <div
          className={`${baseClass} ${buttonBgState}`}
          data-foo={this.state.count}
        >
          <h1>Persons on the table: {this.state.count}</h1>
          {this.state.count > 2 ? (
            <button type="button" onClick={this.decrementCount}>
              -
            </button>
          ) : (
            <button type="button" disabled>
              -
            </button>
          )}
          {this.state.count < 50 ? (
            <button type="button" onClick={this.incrementCount}>
              +
            </button>
          ) : (
            <button type="button" disabled>
              +
            </button>
          )}
        </div>
        <div
          className={`${baseClass} ${buttonBgState}`}
          data-foo={this.state.count2}
        >
          <h1>You are paying for: {this.state.count2}</h1>
          {this.state.count2 > 1 ? (
            <button type="button" onClick={this.decrementCount2}>
              -
            </button>
          ) : (
            <button type="button" disabled>
              -
            </button>
          )}
          {this.state.count2 < this.state.count - 1 ? (
            <button type="button" onClick={this.incrementCount2}>
              +
            </button>
          ) : (
            <button type="button" disabled>
              +
            </button>
          )}
        </div>

        {/*  <h5>to pay: </h5>
        <div className="total">
          {" "}
          {(
            (this.props.MasterTotal / this.state.count) *
            this.state.count2
          ).toFixed(2)}{" "}
          €
        </div> */}

        <PaymentButton
          MasterTotal={
            (this.props.MasterTotal / this.state.count) * this.state.count2
          }
          SplitMethod="Divide"
          billTotal={this.props.billTotal}
          remainingAmount={this.props.remainingAmount}
        ></PaymentButton>
      </div>
    );
  }
}
export default withRouter(Counter);

import React, { Component } from "react";
import { withRouter } from "../../common/with-router";

class AddTip extends Component {
  constructor(props) {
    super(props);

    //console.log(this.props);

    this.addtip = this.addtip.bind(this);
    this.handleCustomAmount = this.handleCustomAmount.bind(this);

    this.state = {
      tip: 0,
      tip_percentage: 0,
      message: "",
      total: 0,
      customAmount: 0,
      active: "notip",
    };
  }

  componentDidMount() {
    var orderId = this.props.router.params.id;
    this.setState({ orderId });
  }

  addtip(tip_perc) {
    this.state.total = this.props.y + tip_perc;
    this.props.MasterTotal(this.state.total);
    this.setState({
      tip_percentage: tip_perc,
    });
  }

  handleCustomAmount(value) {
    this.state.total = this.props.y + value;
    this.props.MasterTotal(this.state.total);
    this.setState({
      tip: this.state.value,
    });
  }

  someFunct(name) {
    this.setState({ active: name });
  }

  render() {
    const { total } = this.state;
    const [isActive, setActive] = "false";

    const ToggleClass = () => {
      setActive(!isActive);
    };

    return (
      <div className="tipz">
        <button
          value="0"
          /*  onClick={() => this.addtip(0)} */
          className={
            this.state.active === "notip"
              ? "m-1 tip btn btn-sm active"
              : "m-1 tip btn btn-sm"
          }
          onClick={() => this.someFunct("notip") + this.addtip(0)}
        >
          no tip
        </button>
        <button
          value="5"
          className={
            this.state.active === "first"
              ? "m-1 tip btn btn-sm active"
              : "m-1 tip btn btn-sm"
          }
          onClick={() => this.someFunct("first") + this.addtip(5)}
          /* onClick={() => this.addtip(5)} */
        >
          5
        </button>
        <button
          value="7"
          className={
            this.state.active === "second"
              ? "m-1 tip btn btn-sm active"
              : "m-1 tip btn btn-sm"
          }
          onClick={() => this.someFunct("second") + this.addtip(7)}
        >
          7
        </button>
        <button
          value="10"
          className={
            this.state.active === "third"
              ? "m-1 tip btn btn-sm active"
              : "m-1 tip btn btn-sm"
          }
          onClick={() => this.someFunct("third") + this.addtip(10)}
        >
          10
        </button>
        <form className="form1 tipform" noValidate autoComplete="off">
          <input
            type="number"
            placeholder="other"
            inputMode="numeric"
            step="0.01"
            min="0"
            /*             pattern="^\d*(\.\d{0,2})?$"
             */
            onChange={(e) =>
              e.target.value
                ? this.handleCustomAmount(parseFloat(e.target.value))
                : this.handleCustomAmount(parseFloat(0))
            }
          ></input>
          {/* <button
            className="tip btn btn-sm"
            type="submit"
            onClick={() => this.onSubmit()}
          >
            OK
          </button> */}
        </form>
      </div>
    );
  }
}

export default withRouter(AddTip);

import React, { useState, useEffect } from "react";
import OrderItemDataService from "../services/orderitem.service";

const Items = ({ oID }) => {
  const [orderitems, setOrderitems] = useState([]);

  useEffect(() => {
    OrderItemDataService.getAll(oID)
      .then((response) => {
        setOrderitems(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [oID]);

  return (
    <ul className="list-group">
      {orderitems &&
        orderitems.map((orderitem, index) => (
          <li className={"list-group-item "} key={index}>
            <div className="itemsinthelist">
              {/* {orderitem.quantity}&nbsp;&nbsp; */}
              {orderitem.ItemDescription}
              &nbsp;&nbsp;&nbsp;&nbsp;
            </div>{" "}
            <div className="prices">
              {orderitem.ItemPrice.toFixed(2)}&nbsp;€
            </div>
          </li>
        ))}
    </ul>
  );
};

export default Items;

import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Place from "./components/Place";
import Items from "./components/Items";
import Payment from "./components/payment/payment";
import PaymentStatus from "./components/payment/stripe/PaymentStatus";
import OrderDataService from "./services/order.service";
import TableDataService from "./services/table.service";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import axios from "axios";

const gitHubUrl = "http://demo.oncloud.gr/s1services";

const stripePromise = loadStripe(
  "pk_test_51IP45QHmKoM90dWU15cj4OjhAoMgFbdrWElNNObYrHHxYUY9XonNa0fdkOl0RQRJxldRf3DUpojvamzUH3bSN3OL00sAPzWaeW"
);

const path1 = window.location.pathname.split("/")[1];
const path2 = window.location.pathname.split("/")[2];

function App() {
  const [userData, setUserData] = useState();
  const [oID, setOID] = useState();
  const [theURL, setTheURL] = useState();

  useEffect(() => {
    /*  getGiHubUserWithAxios(); */
    getOrderFromTable(path1, path2);
  }, []);

  const getGiHubUserWithAxios = async () => {
    //  const response = await axios.get(gitHubUrl);
    /*    var data = {
      service: "login",
      username: "john",
      password: "aitis",
      appId: "2001",
      COMPANY: "1000",
      BRANCH: "1000",
      MODULE: "0",
      REFID: "1",
    }; */
    const response = await axios.post(gitHubUrl /* , data */);
    setUserData(response.data);
    console.log(response.data);
  };

  const getOrderFromTable = (path1, path2) => {
    if (path1 == "o") {
      var theURL = "/o/" + path2;
      var oID = path2;
      setOID(oID);
    } else if (path1 == "t") {
      TableDataService.get(path2).then((response) => {
        var z = response.data;
        var w = z.id;
        console.log(response.data);
        if (0 !== 0) {
          var theURL = "tableDoesNotExist";
          var oID = 0;
          setOID(oID);
          setTheURL(theURL);
        } else {
          OrderDataService.getOrderFromTable(path2)
            .then((response) => {
              console.log(response);
              if (response) {
                var r = response.data;
                r.map((rMapped) => {
                  var theURL = "/o/" + rMapped.id;
                  var oID = rMapped.id;
                  setOID(oID);
                  setTheURL(theURL);
                });
              } else {
                var data = {
                  table_number: path2,
                };
                OrderDataService.create(data).then((response) => {
                  console.log(response);
                  var ra = response.data;
                  console.log(ra);
                  /*       r.map((rMapped) => {
                var theURL = "/o/" + rMapped.id;
                var oID = rMapped.id;
                this.setState({ theURL, oID }, function () {}); 
              });*/
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
    } else {
      const theURL = "404";
      const oID = 0;
      setOID(oID);
      setTheURL(theURL);
    }
  };

  if (oID && window.location.pathname.split("/")[3] !== "completion") {
    return (
      <div className="container">
        <div className="App">
          <header className="App-header"></header>
          {/*  <div className="user-container">
            <h5 className="info-item">{userData}</h5>
            <h5 className="info-item">{userData.location}</h5>
            <h5 className="info-item">{userData.blog}</h5>
            <h5 className="info-item">{userData.company}</h5>
          </div> */}
        </div>
        <Routes>
          <Route
            path="/t/*"
            element={
              <>
                <Navigate replace to={theURL} />
              </>
            }
          />
        </Routes>
        <Place oID={oID} />
        <Items oID={oID} />
        <Payment oID={oID} />
      </div>
    );
  } else if (window.location.pathname.split("/")[3] == "completion") {
    return (
      <div className="container mt-5">
        <Elements stripe={stripePromise}>
          <PaymentStatus />
        </Elements>
      </div>
    );
  } else {
    return <div className="container mt-5">TABLE NOT FOUND</div>;
  }
}

export default App;

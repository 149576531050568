import React, { useState, useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import PaymentDataService from "../../../services/payment.service";
import PaymentItemDataService from "../../../services/paymentitem.service";
import OrderItemDataService from "../../../services/orderitem.service";

const PaymentStatus = () => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    var orderId = window.location.pathname.split("/")[1];
    var paymentId = window.location.pathname.split("/")[4];
    var splitMethod = window.location.pathname.split("/")[6];
    //console.log("payment id is = " + paymentId);
    //http://localhost:443/4/completion?payment_intent=pi_3PWIxmHmKoM90dWU0ByiGAG8&payment_intent_client_secret=pi_3PWIxmHmKoM90dWU0ByiGAG8_secret_n6WzWSglp4BMt5QrcTrDXrMia&redirect_status=succeeded

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Success! Payment received.");

          var paymentData = {
            situation: "paid",
          };

          PaymentDataService.update(paymentId, paymentData)
            .then((res) => {})
            .catch((e) => {
              console.log(e);
            });

          if (splitMethod == "ChooseYourItems") {
            var paymentData2 = {
              paid: "paid",
            };
            PaymentItemDataService.update(paymentId, paymentData2)
              .then((res) => {
                PaymentItemDataService.findByPaid(paymentId)
                  .then((res) => {
                    OrderItemDataService.updatePaid(paymentId, paymentData2)
                      .then((res) => {
                        console.log(res.data);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              })
              .catch((e) => {
                console.log(e);
              });
          }

          break;

        case "processing":
          setMessage(
            "Payment processing. We'll update you when payment is received."
          );
          break;

        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage("Payment failed. Please try another payment method.");
          break;

        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  return message;
};

export default PaymentStatus;

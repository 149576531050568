import http from "../http-common";

class PaymentItemDataService {
  getAll(id) {
    return http.get(`/paymentitems/${id}`);
  }

  get(id) {
    return http.get(`/paymentitems/${id}`);
  }

  create(data) {
    return http.post("/paymentitems", data);
  }

  update(id, data) {
    return http.put(`/paymentitems/${id}`, data);
  }

  delete(id) {
    return http.delete(`/paymentitems/${id}`);
  }

  deleteAll() {
    return http.delete(`/paymentitems`);
  }

  findByTitle(title) {
    return http.get(`/paymentitems/${title}`);
  }

  findBy(paymentid) {
    return http.get(`/paymentitems/pid/${paymentid}`);
  }

  findByPaid(paymentid) {
    return http.get(`/paymentitems/paidid/${paymentid}`);
  }

  sumPaid(id) {
    return http.get(`/paymentitems/orderid/${id}`);
  }
}

export default new PaymentItemDataService();

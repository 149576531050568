import http from "../http-common";

class OrderItemDataService {
  getAll(id) {
    return http.get(`/OrderItems/${id}`);
  }

  sumPrice(id) {
    return http.get(`/orderitems/orderid/${id}`);
  }

  update(id, data) {
    return http.put(`/orderitems/${id}`, data);
  }

  updatePaid(id, data) {
    return http.put(`/orderitems/paid/${id}`, data);
  }
}

export default new OrderItemDataService();

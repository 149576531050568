import React, { useState, useEffect } from "react";
import OrderDataService from "../services/order.service";
import ClientsDataService from "../services/client.service";
import MenuButton from "./payment/items/MenuButton.component";

const Place = ({ oID }) => {
  const [currentOrder, setCurrentOrder] = useState([]);
  const [currentClient, setCurrentClient] = useState([]);

  useEffect(() => {
    OrderDataService.get(oID)
      .then((response) => {
        var a = response.data;
        var b = a.client_id;
        setCurrentOrder(response.data);

        ClientsDataService.get(b)
          .then((response) => {
            setCurrentClient(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }, [oID]);

  const preLogoPath = "../../assets/images/logos/" + currentClient.logo;
  const preMenuPath =
    "../../assets/menus/" +
    currentClient.menu +
    "#toolbar=0&navpanes=0&scrollbar=0";
  return (
    <div>
      <div className="magazi">
        <MenuButton oID={oID} menuPath={preMenuPath}></MenuButton>
      </div>
      <div className="magazi">
        <img id="logo" alt="Place Logo" src={preLogoPath} />
        <p>
          {currentClient.name}: table {currentOrder.table_number}
        </p>
      </div>
      <div className="clearhere"></div>
    </div>
  );
};

export default Place;

import React, { Component } from "react";
import { withRouter } from "../../../common/with-router";
import OrderItemDataService from "../../../services/orderitem.service";
import Checkbox from "../items/Checkbox.component";
import PaymentButton from "../items/PaymentButton.component";

class chooseThings2 extends Component {
  constructor(props) {
    super(props);
    this.calculateTotal = this.calculateTotal.bind(this);

    this.state = {
      status: false,
      total: 0,
      toPay: 0,
      arr: [],
      payitemsnumber: 0,
      billTotal: this.props.MasterTotal,
    };

    // console.log(this.props);
  }

  componentDidMount() {
    this.retrieveTutorials(this.props.oID);
  }

  retrieveTutorials(id) {
    OrderItemDataService.getAll(id)
      .then((response) => {
        this.setState({
          orderitems: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  calculateTotal(input, checked, desc, id, orderId) {
    let { total } = this.state;
    if (checked) {
      total += input;
      this.state.payitemsnumber += 3;
      // append new value to the array
      this.state.arr.push(orderId, desc, input);
    } else {
      total -= input;
      this.state.payitemsnumber -= 3;
      const index = this.state.arr.indexOf(id);
      console.log(index);
      if (index > -1) {
        // only splice array when item is found
        this.state.arr.splice(index, 3); // 2nd parameter means remove 2 item only
      }
    }
    console.log("eyo arr", this.state.arr, " kai ", this.state.payitemsnumber);

    this.setState({
      total,
    });
  }

  sendAmountToPay() {
    alert("pay divided amount, " + this.state.toPay);
  }

  render() {
    const { orderitems } = this.state;

    return (
      <div className="list row">
        <div className="col-md-5 yeah">
          <ul className="list-group">
            {orderitems &&
              orderitems.map((orderitem, index) =>
                orderitem.paid != "paid" ? (
                  <li className={"list-group-item "} key={index}>
                    <Checkbox
                      calculateTotal={this.calculateTotal}
                      value={orderitem.ItemPrice}
                      orderId={orderitem.id}
                      id={index}
                      ItemDescription={orderitem.ItemDescription}
                    />
                    &nbsp; {orderitem.quantity}&nbsp;{" "}
                    {orderitem.ItemDescription} &nbsp;&nbsp;€
                    {orderitem.ItemPrice.toFixed(2)}
                  </li>
                ) : (
                  <li className={"list-group-item paid-li"} key={index}>
                    &nbsp; {orderitem.quantity}&nbsp;{" "}
                    {orderitem.ItemDescription} &nbsp;&nbsp;€
                    {orderitem.ItemPrice.toFixed(2)}
                  </li>
                )
              )}
            {/*             <h5>to pay: </h5>
            <p> {this.state.total}</p> */}

            <PaymentButton
              MasterTotal={this.state.total}
              SplitMethod="ChooseYourItems"
              ButtonTxt=" for my Items"
              arr={this.state.arr}
              payitemsnumber={this.state.payitemsnumber}
              billTotal={this.props.billTotal}
              remainingAmount={this.props.remainingAmount}
            ></PaymentButton>
          </ul>
        </div>
      </div>
    );
  }
}
export default withRouter(chooseThings2);
